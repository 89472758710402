import React, {useRef, useEffect, useState} from 'react';
import { Formulas, formulas }from './formulas';
import Equation from './Components/Equation';
import Modal from 'react-modal';
import EquationModal from './Components/EquationModal';
import { posts } from './data/posts';

function App() {
  const [equations, setEquations] = useState<string[]>(Object.keys(formulas));
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEq, setSelectedEq] = useState<string>('');

  const [mass, setMass] = useState<string|null>("");
  const [distance, setDistance] = useState<string|null>("");
  const [time, setTime] = useState<string|null>("");
  const [filterEquation,setFilterEquation] = useState('');
  const [filteredEquations,setFilteredEquations] = useState<string[]>(Object.keys(formulas));
  function openModal(eq:string) {
    setSelectedEq(eq);
    setIsOpen(true);
  }

  useEffect(()=>{
    setEquations(Object.keys(formulas));
  },[])

  useEffect(()=>{
    const filtered = equations.filter(eq=>{
      const nums = eq.match(/-?\d+/g)?.map(Number);
      if(!nums) return;
      return (
        (nums[0]===Number(mass) || mass==="") &&
        (nums[1]===Number(distance) || distance==="") &&
        (nums[2]===Number(time)  || time==="")
        );
    })
    setFilteredEquations(filtered)
  },[mass,distance,time])

  
  return (
    <div className="App">
      <div className="header">
        <h1>Known Unknowns - The Units Project</h1>
        <h2>Discover a piece of knowledge that hasn't been uncovered yet. The following unit combinations are all the possible units for time (s), distance (m), and mass (kg) up to the third power. We now know what we don’t know, so please contribute what you discover. </h2>
       
      </div>
      <div className="equation-filter">
          <form className="equation-filter-form">
            <var>kg <sup> <input className="filter-SI" type="text"  onChange={(e) => setMass(e.target.value)}/></sup></var>
            <var>m <sup> <input className="filter-SI" type="text"  onChange={(e) => setDistance(e.target.value)}/></sup></var>
            <var>s <sup> <input className="filter-SI" type="text" onChange={(e) => setTime(e.target.value)}/></sup></var>
          </form>
        </div>
      <div className="equation-wrapper">
       
        <div className="equations"> 
          { filteredEquations?filteredEquations.map(eq => {
            return<Equation key={eq} eq={eq} openModal={()=>{openModal(eq)}} post={posts[eq]} />})
          :<></>}
        </div>
      </div>

      {selectedEq?
        <EquationModal eq={selectedEq} post = {posts[selectedEq]} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
      :<></>}
       
    </div>
  );
}

export default App;
