import { useState } from 'react'; 
import CalcEquationHTML from '../utils/calcEquationHTML';

interface PostType {
  "id": string;
  "title": string;
  "description": string;
  "author": string;
  "created": string;
  "source": string;
}
function Equation({eq, openModal, post}: {eq:string; openModal:() => void; post: PostType;}) {
  
  const isFilled = post.title !== "";
  const [isShown,setIsShown] = useState(false);

  return (
    <div 
      className={`math-equation ${isFilled? 'filled-equation': ''}`} 
      id={eq} 
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onTouchStart={() => setIsShown(true)}
      onTouchMove={() => setIsShown(true)}
      onTouchCancel={() => setIsShown(false)}
      onTouchEnd={() => setIsShown(false)}
      onClick={openModal}  >
      {/* <div className="eq-icon">
        <Icon name={ICONS.CHECK} size={16} color={COLORS.GREEN} /> 
      </div> */}
      {/* <div className="eq-details-num">
        {post.id}
      </div> */}
      <CalcEquationHTML eq={eq}/>
      
      {(isShown && isFilled) && (
        <div className="equation-tooltip">
          {post.title}
        </div>
      )}


    </div>
  )
}

export default Equation
