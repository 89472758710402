type UnitComponent = {
    kg: number | undefined;
    m: number | undefined;
    s: number | undefined;
  }

function CalcEquationHTML( { eq }: {eq:string} ) {
    const parts = eq.split("*");

    const topComponent:UnitComponent = {
        kg: 0,
        m: 0,
        s: 0,
    };

    const bottomComponent:UnitComponent ={
        kg: 0,
        m: 0,
        s: 0,
    }

    parts.forEach((part)=>{
        const  [unit, power] = part.split('^');
        const numPower = Number(power)

        if(numPower === 0){
        return
        }
        if(numPower > 0){
        topComponent[unit as keyof UnitComponent] = numPower
        }
        if(numPower < 0){
        bottomComponent[unit as keyof UnitComponent] = -numPower
        }
    })

    const textConverter = (component:UnitComponent, unit:string) =>{
        let text = <></>;

        if(component[unit as keyof UnitComponent]===1 || component[unit as keyof UnitComponent]===-1 ){
        text = <var>{unit}<sup></sup></var>
        }

        else if(component[unit as keyof UnitComponent]!==0 ){
        text = <var>{unit}<sup>{component[unit as keyof UnitComponent]}</sup></var>
        }
        return text 
    }

    const partIsBlank = (component:UnitComponent) =>{
        if(component.kg === 0 && component.m === 0 && component.s === 0 ){
        return true
        }
        else{
        return false
        }
    }



    const topKgText = textConverter(topComponent, "kg" )
    const topMText = textConverter(topComponent, "m" )
    const topSText = textConverter(topComponent, "s" )

    const bottomKgText = textConverter(bottomComponent, "kg" )
    const bottomMText = textConverter(bottomComponent, "m" )
    const bottomSText = textConverter(bottomComponent, "s" )

    const topIsBlank = partIsBlank(topComponent);
    const bottomIsBlank = partIsBlank(bottomComponent);

    return (
        <>
            {!topIsBlank ?
            <div className="top">
                {topKgText}
                {topMText}
                {topSText}
            </div>:
            1
            }
            {!bottomIsBlank?
            <>
                <div className="divisor"></div>
                <div className="bottom">
                {bottomKgText}
                {bottomMText}
                {bottomSText}
                </div>
            </>:
            <></>
            }
        </>
    )
}

export default CalcEquationHTML
