
export interface Formulas {
    'kg^0*m^0*s^1': string;
    'kg^0*m^1*s^0': string;
    'kg^1*m^0*s^0': string;
    'kg^-1*m^0*s^0': string;
    'kg^0*m^-1*s^0': string;
    'kg^0*m^0*s^-1': string;
    'kg^0*m^0*s^2': string;
    'kg^0*m^1*s^1': string;
    'kg^0*m^2*s^0': string;
    'kg^1*m^0*s^1': string;
    'kg^1*m^1*s^0': string;
    'kg^2*m^0*s^0': string;
    'kg^-1*m^0*s^1': string;
    'kg^-1*m^1*s^0': string;
    'kg^0*m^-1*s^1': string;
    'kg^0*m^1*s^-1': string;
    'kg^1*m^-1*s^0': string;
    'kg^1*m^0*s^-1': string;
    'kg^-2*m^0*s^0': string;
    'kg^-1*m^-1*s^0': string;
    'kg^-1*m^0*s^-1': string;
    'kg^0*m^-2*s^0': string;
    'kg^0*m^-1*s^-1': string;
    'kg^0*m^0*s^-2': string;
    'kg^0*m^0*s^3': string;
    'kg^0*m^1*s^2': string;
    'kg^0*m^2*s^1': string;
    'kg^0*m^3*s^0': string;
    'kg^1*m^0*s^2': string;
    'kg^1*m^1*s^1': string;
    'kg^1*m^2*s^0': string;
    'kg^2*m^0*s^1': string;
    'kg^2*m^1*s^0': string;
    'kg^3*m^0*s^0': string;
    'kg^-1*m^0*s^2': string;
    'kg^-1*m^1*s^1': string;
    'kg^-1*m^2*s^0': string;
    'kg^0*m^-1*s^2': string;
    'kg^0*m^2*s^-1': string;
    'kg^1*m^-1*s^1': string;
    'kg^1*m^1*s^-1': string;
    'kg^2*m^-1*s^0': string;
    'kg^2*m^0*s^-1': string;
    'kg^-2*m^0*s^1': string;
    'kg^-2*m^1*s^0': string;
    'kg^-1*m^-1*s^1': string;
    'kg^-1*m^1*s^-1': string;
    'kg^0*m^-2*s^1': string;
    'kg^0*m^1*s^-2': string;
    'kg^1*m^-2*s^0': string;
    'kg^1*m^-1*s^-1': string;
    'kg^1*m^0*s^-2': string;
    'kg^-3*m^0*s^0': string;
    'kg^-2*m^-1*s^0': string;
    'kg^-2*m^0*s^-1': string;
    'kg^-1*m^-2*s^0': string;
    'kg^-1*m^-1*s^-1': string;
    'kg^-1*m^0*s^-2': string;
    'kg^0*m^-3*s^0': string;
    'kg^0*m^-2*s^-1': string;
    'kg^0*m^-1*s^-2': string;
    'kg^0*m^0*s^-3': string;
    'kg^0*m^1*s^3': string;
    'kg^0*m^2*s^2': string;
    'kg^0*m^3*s^1': string;
    'kg^1*m^0*s^3': string;
    'kg^1*m^1*s^2': string;
    'kg^1*m^2*s^1': string;
    'kg^1*m^3*s^0': string;
    'kg^2*m^0*s^2': string;
    'kg^2*m^1*s^1': string;
    'kg^2*m^2*s^0': string;
    'kg^3*m^0*s^1': string;
    'kg^3*m^1*s^0': string;
    'kg^-1*m^0*s^3': string;
    'kg^-1*m^1*s^2': string;
    'kg^-1*m^2*s^1': string;
    'kg^-1*m^3*s^0': string;
    'kg^0*m^-1*s^3': string;
    'kg^0*m^3*s^-1': string;
    'kg^1*m^-1*s^2': string;
    'kg^1*m^2*s^-1': string;
    'kg^2*m^-1*s^1': string;
    'kg^2*m^1*s^-1': string;
    'kg^3*m^-1*s^0': string;
    'kg^3*m^0*s^-1': string;
    'kg^-2*m^0*s^2': string;
    'kg^-2*m^1*s^1': string;
    'kg^-2*m^2*s^0': string;
    'kg^-1*m^-1*s^2': string;
    'kg^-1*m^2*s^-1': string;
    'kg^0*m^-2*s^2': string;
    'kg^0*m^2*s^-2': string;
    'kg^1*m^-2*s^1': string;
    'kg^1*m^1*s^-2': string;
    'kg^2*m^-2*s^0': string;
    'kg^2*m^-1*s^-1': string;
    'kg^2*m^0*s^-2': string;
    'kg^-3*m^0*s^1': string;
    'kg^-3*m^1*s^0': string;
    'kg^-2*m^-1*s^1': string;
    'kg^-2*m^1*s^-1': string;
    'kg^-1*m^-2*s^1': string;
    'kg^-1*m^1*s^-2': string;
    'kg^0*m^-3*s^1': string;
    'kg^0*m^1*s^-3': string;
    'kg^1*m^-3*s^0': string;
    'kg^1*m^-2*s^-1': string;
    'kg^1*m^-1*s^-2': string;
    'kg^1*m^0*s^-3': string;
    'kg^-3*m^-1*s^0': string;
    'kg^-3*m^0*s^-1': string;
    'kg^-2*m^-2*s^0': string;
    'kg^-2*m^-1*s^-1': string;
    'kg^-2*m^0*s^-2': string;
    'kg^-1*m^-3*s^0': string;
    'kg^-1*m^-2*s^-1': string;
    'kg^-1*m^-1*s^-2': string;
    'kg^-1*m^0*s^-3': string;
    'kg^0*m^-3*s^-1': string;
    'kg^0*m^-2*s^-2': string;
    'kg^0*m^-1*s^-3': string;
    'kg^0*m^2*s^3': string;
    'kg^0*m^3*s^2': string;
    'kg^1*m^1*s^3': string;
    'kg^1*m^2*s^2': string;
    'kg^1*m^3*s^1': string;
    'kg^2*m^0*s^3': string;
    'kg^2*m^1*s^2': string;
    'kg^2*m^2*s^1': string;
    'kg^2*m^3*s^0': string;
    'kg^3*m^0*s^2': string;
    'kg^3*m^1*s^1': string;
    'kg^3*m^2*s^0': string;
    'kg^-1*m^1*s^3': string;
    'kg^-1*m^2*s^2': string;
    'kg^-1*m^3*s^1': string;
    'kg^1*m^-1*s^3': string;
    'kg^1*m^3*s^-1': string;
    'kg^2*m^-1*s^2': string;
    'kg^2*m^2*s^-1': string;
    'kg^3*m^-1*s^1': string;
    'kg^3*m^1*s^-1': string;
    'kg^-2*m^0*s^3': string;
    'kg^-2*m^1*s^2': string;
    'kg^-2*m^2*s^1': string;
    'kg^-2*m^3*s^0': string;
    'kg^-1*m^-1*s^3': string;
    'kg^-1*m^3*s^-1': string;
    'kg^0*m^-2*s^3': string;
    'kg^0*m^3*s^-2': string;
    'kg^1*m^-2*s^2': string;
    'kg^1*m^2*s^-2': string;
    'kg^2*m^-2*s^1': string;
    'kg^2*m^1*s^-2': string;
    'kg^3*m^-2*s^0': string;
    'kg^3*m^-1*s^-1': string;
    'kg^3*m^0*s^-2': string;
    'kg^-3*m^0*s^2': string;
    'kg^-3*m^1*s^1': string;
    'kg^-3*m^2*s^0': string;
    'kg^-2*m^-1*s^2': string;
    'kg^-2*m^2*s^-1': string;
    'kg^-1*m^-2*s^2': string;
    'kg^-1*m^2*s^-2': string;
    'kg^0*m^-3*s^2': string;
    'kg^0*m^2*s^-3': string;
    'kg^1*m^-3*s^1': string;
    'kg^1*m^1*s^-3': string;
    'kg^2*m^-3*s^0': string;
    'kg^2*m^-2*s^-1': string;
    'kg^2*m^-1*s^-2': string;
    'kg^2*m^0*s^-3': string;
    'kg^-3*m^-1*s^1': string;
    'kg^-3*m^1*s^-1': string;
    'kg^-2*m^-2*s^1': string;
    'kg^-2*m^1*s^-2': string;
    'kg^-1*m^-3*s^1': string;
    'kg^-1*m^1*s^-3': string;
    'kg^1*m^-3*s^-1': string;
    'kg^1*m^-2*s^-2': string;
    'kg^1*m^-1*s^-3': string;
    'kg^-3*m^-2*s^0': string;
    'kg^-3*m^-1*s^-1': string;
    'kg^-3*m^0*s^-2': string;
    'kg^-2*m^-3*s^0': string;
    'kg^-2*m^-2*s^-1': string;
    'kg^-2*m^-1*s^-2': string;
    'kg^-2*m^0*s^-3': string;
    'kg^-1*m^-3*s^-1': string;
    'kg^-1*m^-2*s^-2': string;
    'kg^-1*m^-1*s^-3': string;
    'kg^0*m^-3*s^-2': string;
    'kg^0*m^-2*s^-3': string;
    'kg^0*m^3*s^3': string;
    'kg^1*m^2*s^3': string;
    'kg^1*m^3*s^2': string;
    'kg^2*m^1*s^3': string;
    'kg^2*m^2*s^2': string;
    'kg^2*m^3*s^1': string;
    'kg^3*m^0*s^3': string;
    'kg^3*m^1*s^2': string;
    'kg^3*m^2*s^1': string;
    'kg^3*m^3*s^0': string;
    'kg^-1*m^2*s^3': string;
    'kg^-1*m^3*s^2': string;
    'kg^2*m^-1*s^3': string;
    'kg^2*m^3*s^-1': string;
    'kg^3*m^-1*s^2': string;
    'kg^3*m^2*s^-1': string;
    'kg^-2*m^1*s^3': string;
    'kg^-2*m^2*s^2': string;
    'kg^-2*m^3*s^1': string;
    'kg^1*m^-2*s^3': string;
    'kg^1*m^3*s^-2': string;
    'kg^2*m^-2*s^2': string;
    'kg^2*m^2*s^-2': string;
    'kg^3*m^-2*s^1': string;
    'kg^3*m^1*s^-2': string;
    'kg^-3*m^0*s^3': string;
    'kg^-3*m^1*s^2': string;
    'kg^-3*m^2*s^1': string;
    'kg^-3*m^3*s^0': string;
    'kg^-2*m^-1*s^3': string;
    'kg^-2*m^3*s^-1': string;
    'kg^-1*m^-2*s^3': string;
    'kg^-1*m^3*s^-2': string;
    'kg^0*m^-3*s^3': string;
    'kg^0*m^3*s^-3': string;
    'kg^1*m^-3*s^2': string;
    'kg^1*m^2*s^-3': string;
    'kg^2*m^-3*s^1': string;
    'kg^2*m^1*s^-3': string;
    'kg^3*m^-3*s^0': string;
    'kg^3*m^-2*s^-1': string;
    'kg^3*m^-1*s^-2': string;
    'kg^3*m^0*s^-3': string;
    'kg^-3*m^-1*s^2': string;
    'kg^-3*m^2*s^-1': string;
    'kg^-2*m^-2*s^2': string;
    'kg^-2*m^2*s^-2': string;
    'kg^-1*m^-3*s^2': string;
    'kg^-1*m^2*s^-3': string;
    'kg^2*m^-3*s^-1': string;
    'kg^2*m^-2*s^-2': string;
    'kg^2*m^-1*s^-3': string;
    'kg^-3*m^-2*s^1': string;
    'kg^-3*m^1*s^-2': string;
    'kg^-2*m^-3*s^1': string;
    'kg^-2*m^1*s^-3': string;
    'kg^1*m^-3*s^-2': string;
    'kg^1*m^-2*s^-3': string;
    'kg^-3*m^-3*s^0': string;
    'kg^-3*m^-2*s^-1': string;
    'kg^-3*m^-1*s^-2': string;
    'kg^-3*m^0*s^-3': string;
    'kg^-2*m^-3*s^-1': string;
    'kg^-2*m^-2*s^-2': string;
    'kg^-2*m^-1*s^-3': string;
    'kg^-1*m^-3*s^-2': string;
    'kg^-1*m^-2*s^-3': string;
    'kg^0*m^-3*s^-3': string;
    'kg^1*m^3*s^3': string;
    'kg^2*m^2*s^3': string;
    'kg^2*m^3*s^2': string;
    'kg^3*m^1*s^3': string;
    'kg^3*m^2*s^2': string;
    'kg^3*m^3*s^1': string;
    'kg^-1*m^3*s^3': string;
    'kg^3*m^-1*s^3': string;
    'kg^3*m^3*s^-1': string;
    'kg^-2*m^2*s^3': string;
    'kg^-2*m^3*s^2': string;
    'kg^2*m^-2*s^3': string;
    'kg^2*m^3*s^-2': string;
    'kg^3*m^-2*s^2': string;
    'kg^3*m^2*s^-2': string;
    'kg^-3*m^1*s^3': string;
    'kg^-3*m^2*s^2': string;
    'kg^-3*m^3*s^1': string;
    'kg^1*m^-3*s^3': string;
    'kg^1*m^3*s^-3': string;
    'kg^2*m^-3*s^2': string;
    'kg^2*m^2*s^-3': string;
    'kg^3*m^-3*s^1': string;
    'kg^3*m^1*s^-3': string;
    'kg^-3*m^-1*s^3': string;
    'kg^-3*m^3*s^-1': string;
    'kg^-2*m^-2*s^3': string;
    'kg^-2*m^3*s^-2': string;
    'kg^-1*m^-3*s^3': string;
    'kg^-1*m^3*s^-3': string;
    'kg^3*m^-3*s^-1': string;
    'kg^3*m^-2*s^-2': string;
    'kg^3*m^-1*s^-3': string;
    'kg^-3*m^-2*s^2': string;
    'kg^-3*m^2*s^-2': string;
    'kg^-2*m^-3*s^2': string;
    'kg^-2*m^2*s^-3': string;
    'kg^2*m^-3*s^-2': string;
    'kg^2*m^-2*s^-3': string;
    'kg^-3*m^-3*s^1': string;
    'kg^-3*m^1*s^-3': string;
    'kg^1*m^-3*s^-3': string;
    'kg^-3*m^-3*s^-1': string;
    'kg^-3*m^-2*s^-2': string;
    'kg^-3*m^-1*s^-3': string;
    'kg^-2*m^-3*s^-2': string;
    'kg^-2*m^-2*s^-3': string;
    'kg^-1*m^-3*s^-3': string;
    'kg^2*m^3*s^3': string;
    'kg^3*m^2*s^3': string;
    'kg^3*m^3*s^2': string;
    'kg^-2*m^3*s^3': string;
    'kg^3*m^-2*s^3': string;
    'kg^3*m^3*s^-2': string;
    'kg^-3*m^2*s^3': string;
    'kg^-3*m^3*s^2': string;
    'kg^2*m^-3*s^3': string;
    'kg^2*m^3*s^-3': string;
    'kg^3*m^-3*s^2': string;
    'kg^3*m^2*s^-3': string;
    'kg^-3*m^-2*s^3': string;
    'kg^-3*m^3*s^-2': string;
    'kg^-2*m^-3*s^3': string;
    'kg^-2*m^3*s^-3': string;
    'kg^3*m^-3*s^-2': string;
    'kg^3*m^-2*s^-3': string;
    'kg^-3*m^-3*s^2': string;
    'kg^-3*m^2*s^-3': string;
    'kg^2*m^-3*s^-3': string;
    'kg^-3*m^-3*s^-2': string;
    'kg^-3*m^-2*s^-3': string;
    'kg^-2*m^-3*s^-3': string;
    'kg^3*m^3*s^3': string;
    'kg^-3*m^3*s^3': string;
    'kg^3*m^-3*s^3': string;
    'kg^3*m^3*s^-3': string;
    'kg^-3*m^-3*s^3': string;
    'kg^-3*m^3*s^-3': string;
    'kg^3*m^-3*s^-3': string;
    'kg^-3*m^-3*s^-3': string;
  }


export const formulas:Formulas= {
        
    'kg^0*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><mi>s</mi></mrow></math>`,
    'kg^0*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi></msup></mrow></math>`,
    'kg^1*m^0*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup></mrow></math>`,
    'kg^-1*m^0*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^0*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^0*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></math>`,
    'kg^1*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^1*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></math>`,
    'kg^2*m^0*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></math>`,
    'kg^-1*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^0*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^0*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^0*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></math>`,
    'kg^1*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^1*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^1*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></math>`,
    'kg^2*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^2*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></math>`,
    'kg^3*m^0*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></math>`,
    'kg^-1*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^0*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^0*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^1*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^1*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^1*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^1*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></math>`,
    'kg^2*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^2*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^2*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></math>`,
    'kg^3*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^3*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></math>`,
    'kg^-1*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^0*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^1*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^1*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^1*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^2*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^2*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^2*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^2*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></math>`,
    'kg^3*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^3*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^3*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></math>`,
    'kg^-1*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^1*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^1*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^2*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^2*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^2*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^3*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^3*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^3*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^3*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></math>`,
    'kg^-1*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^0': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^0*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^0*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^2*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^2*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^3*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^3*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^3*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></math>`,
    'kg^-1*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^1*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^-1': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-1*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-1*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^3*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^3*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></math>`,
    'kg^-2*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>2</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^2*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>2</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^-2': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>2</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-2*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-2*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>2</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></math>`,
    'kg^-3*m^3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>s</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>m</mi><mn>3</mn></msup></mrow><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^3*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mrow><msup><mi>kg</mi><mn>3</mn></msup></mrow><mrow><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
    'kg^-3*m^-3*s^-3': `<math xmlns='http://www.w3.org/1998/Math/MathML'><mfrac><mn>1</mn><mrow><msup><mi>kg</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>m</mi><mn>3</mn></msup><mspace width="1px"><msup><mi>s</mi><mn>3</mn></msup></mrow></mfrac></math>`,
}
