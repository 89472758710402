import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

import Modal from "react-modal";
import CalcEquationHTML from "../utils/calcEquationHTML";

import Icon, { ICONS, COLORS } from "../Icons/icon";

function urlDomain(data: string) {
  var a = document.createElement("a");
  a.href = data;
  return a.hostname;
}

function EquationModal({ eq, modalIsOpen, setIsOpen, post}: { modalIsOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>>; eq: string; post: any;}) {
  const [showMore, setShowMore] = useState(false);
  const emailBody = `Let us know what you discoved%0A%0A"title":%20What%20is%20the%20property's%20title?%0A"description":%20Give%20a%20good%20description%20of%20the%20property%0A"author":%20What%20is%20your%20name?%0A"source":%20What%20are%20your%20sources?`
  //  const inverseArr = eq.split('*').map(el=>el.split('^')).map(el=>-1*Number(el[1]));

  // const inverse = `kg^${inverseArr[0]}*m^${inverseArr[1]}*s^${inverseArr[2]}`;
  let subtitle: any;

  const allPostsRef = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function openShowMore(){
    setShowMore(prev => !prev);
  }

  const customStyles = {
    content: {
      width: "50vw",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      id="equation-modal"
    >
      <div className="top-header">
        <div className="modal-equation">
          <CalcEquationHTML eq={eq} />
        </div>

        <div className="most-accepted">
          <div className="most-accepted-element">
            <div className="post-title">
              <b>{post.title ? post.title: ""}</b>
            </div>
          </div>
          <div className="most-accepted-element">
            <div className="post-text">{post.description ? post.description : ""}</div>
          </div>
          <div className="most-accepted-element">
          {post.author ? <div className="post-text">Submitted by: {post.author} </div>:<></>}
          </div>
          <div className="most-accepted-element">
            <div className="post-text">
              {post.source?<a href={post.source} target="_blank" rel="noopener noreferrer">{urlDomain(post.source)}</a>:<></>}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="posts-container">
        <div className="show-more-posts" onClick={openShowMore}>
          <div className="show-more-text">Show more </div>
          <div className={`dropdown-arrow ${showMore ? "show-more":""}`}>
            <Icon name={ICONS.DROPDOWN} size={36} color={COLORS.GREEN} />
          </div>
        </div>
        <div className={`all-posts ${showMore ? "show-more":""}`} ref={allPostsRef}>
          {posts.map((post) => {
            return (
              <div className="post-container">
                <div className="post-text">{post.title}</div>
                <div className="post-text">{post.description}</div>
              </div>
            );
          })}
        </div>
      </div> */}

      <div className="submit-new-post">
      
        <b>Got something to add? <u onClick={()=>{window.open(`mailto:scottlaughlinengineering@gmail.com?subject=The%20Unit%20Project%20Post%20for%20${eq}&body=${emailBody}`)}}>Submit a post</u></b> 
      </div>
    </Modal>
  );
}

export default EquationModal;
