

export const posts:any= 
{
    "kg^0*m^0*s^1": {
        "id": "1",
        "title": "Time",
        "description": "Time is the continued sequence of existence and events that occurs in an apparently irreversible succession from the past, through the present, into the future.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Time"},
    "kg^0*m^1*s^0": {
        "id": "2",
        "title": "Distance",
        "description": "Distance is a numerical or occasionally qualitative measurement of how far apart objects or points are.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Distance"},
    "kg^1*m^0*s^0": {
        "id": "3",
        "title": "Mass",
        "description": "Mass is the quantity of matter in a physical body. It is also a measure of the body",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Mass"},
    "kg^-1*m^0*s^0": {
        "id": "4",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^0": {
        "id": "5",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^0*s^-1": {
        "id": "6",
        "title": "Frequency",
        "description": "Frequency is the number of occurrences of a repeating event per unit of time.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Frequency"},
    "kg^0*m^0*s^2": {
        "id": "7",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^1*s^1": {
        "id": "8",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^0": {
        "id": "9",
        "title": "Area",
        "description": "Area is the quantity that expresses the extent of a region on the plane or on a curved surface. ",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Area"},
    "kg^1*m^0*s^1": {
        "id": "10",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^0": {
        "id": "11",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^0*s^0": {
        "id": "12",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^0*s^1": {
        "id": "13",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^0": {
        "id": "14",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^1": {
        "id": "15",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^1*s^-1": {
        "id": "16",
        "title": "Velocity",
        "description": "Velocity is the directional speed of a object in motion as an indication of its rate of change in position as observed from a particular frame of reference and as measured by a particular standard of time (e.g. 60 km/h northbound). Velocity is a fundamental concept in kinematics, the branch of classical mechanics that describes the motion of bodies.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Velocity"},
    "kg^1*m^-1*s^0": {
        "id": "17",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^0*s^-1": {
        "id": "18",
        "title": "Mass flow rate",
        "description": "In physics and engineering, mass flow rate is the mass of a substance which passes per unit of time. Its unit is kilogram per second in SI units, and slug per second or pound per second in US customary units.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Mass_flow_rate"},
    "kg^-2*m^0*s^0": {
        "id": "19",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^0": {
        "id": "20",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^0*s^-1": {
        "id": "21",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^0": {
        "id": "22",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^-1": {
        "id": "23",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^0*s^-2": {
        "id": "24",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^0*s^3": {
        "id": "25",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^1*s^2": {
        "id": "26",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^1": {
        "id": "27",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^0": {
        "id": "28",
        "title": "Volume",
        "description": "Volume is a measure of occupied three-dimensional space",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Volume"},
    "kg^1*m^0*s^2": {
        "id": "29",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^1": {
        "id": "30",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^0": {
        "id": "31",
        "title": "Moment of Inertia",
        "description": "The moment of inertia, otherwise known as the mass moment of inertia, angular mass, second moment of mass, or most accurately, rotational inertia, of a rigid body is a quantity that determines the torque needed for a desired angular acceleration about a rotational axis, akin to how mass determines the force needed for a desired acceleration.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Moment_of_inertia"},
    "kg^2*m^0*s^1": {
        "id": "32",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^0": {
        "id": "33",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^0": {
        "id": "34",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^0*s^2": {
        "id": "35",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^1": {
        "id": "36",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^0": {
        "id": "37",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^2": {
        "id": "38",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^-1": {
        "id": "39",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-1*s^1": {
        "id": "40",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^-1": {
        "id": "41",
        "title": "Momentum",
        "description": "In Newtonian mechanics, linear momentum, translational momentum, or simply momentum is the product of the mass and velocity of an object. It is a vector quantity, possessing a magnitude and a direction. If m is an object's mass and v is its velocity (also a vector quantity), then the object's momentum P is P=m*v",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Momentum"},
    "kg^2*m^-1*s^0": {
        "id": "42",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^0*s^-1": {
        "id": "43",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^0*s^1": {
        "id": "44",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^0": {
        "id": "45",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^1": {
        "id": "46",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^-1": {
        "id": "47",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^1": {
        "id": "48",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^1*s^-2": {
        "id": "49",
        "title": "Acceleration",
        "description": "In mechanics, acceleration is the rate of change of the velocity of an object with respect to time",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Acceleration"},
    "kg^1*m^-2*s^0": {
        "id": "50",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-1*s^-1": {
        "id": "51",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^0*s^-2": {
        "id": "52",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^0": {
        "id": "53",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^0": {
        "id": "54",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^0*s^-1": {
        "id": "55",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^0": {
        "id": "56",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^-1": {
        "id": "57",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^0*s^-2": {
        "id": "58",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-3*s^0": {
        "id": "59",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^-1": {
        "id": "60",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^-2": {
        "id": "61",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^0*s^-3": {
        "id": "62",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^1*s^3": {
        "id": "63",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^2": {
        "id": "64",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^1": {
        "id": "65",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^0*s^3": {
        "id": "66",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^2": {
        "id": "67",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^1": {
        "id": "68",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^0": {
        "id": "69",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^0*s^2": {
        "id": "70",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^1": {
        "id": "71",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^0": {
        "id": "72",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^1": {
        "id": "73",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^0": {
        "id": "74",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^0*s^3": {
        "id": "75",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^2": {
        "id": "76",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^1": {
        "id": "77",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^0": {
        "id": "78",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^3": {
        "id": "79",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^-1": {
        "id": "80",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-1*s^2": {
        "id": "81",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^-1": {
        "id": "82",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-1*s^1": {
        "id": "83",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^-1": {
        "id": "84",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^0": {
        "id": "85",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^-1": {
        "id": "86",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^0*s^2": {
        "id": "87",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^1": {
        "id": "88",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^0": {
        "id": "89",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^2": {
        "id": "90",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^-1": {
        "id": "91",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^2": {
        "id": "92",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^-2": {
        "id": "93",
        "title": "Specific Energy",
        "description": "Specific energy or massic energy is energy per unit mass.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Specific_energy"},
    "kg^1*m^-2*s^1": {
        "id": "94",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^-2": {
        "id": "95",
        "title": "Force",
        "description": "In physics, a force is an influence that can change the motion of an object. A force can cause an object with mass to change its velocity (e.g. moving from a state of rest), i.e., to accelerate. ",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Force"},
    "kg^2*m^-2*s^0": {
        "id": "96",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-1*s^-1": {
        "id": "97",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^0*s^-2": {
        "id": "98",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^1": {
        "id": "99",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^0": {
        "id": "100",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^1": {
        "id": "101",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^-1": {
        "id": "102",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^1": {
        "id": "103",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^-2": {
        "id": "104",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-3*s^1": {
        "id": "105",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^1*s^-3": {
        "id": "106",
        "title": "Jerk",
        "description": "In physics, jerk or jolt is the rate at which an object's acceleration changes with respect to time. It is a vector quantity (having both magnitude and direction). Jerk is most commonly denoted by the symbol j",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Jerk_(physics)"},
    "kg^1*m^-3*s^0": {
        "id": "107",
        "title": "Density",
        "description": "Density (volumetric mass density or specific mass) is the substance's mass per unit of volume. The symbol most often used for density is ρ (the lower case Greek letter rho), although the Latin letter D can also be used. Mathematically, density is defined as mass divided by volume:",
        "author": "Scott Laughlin",
        "created": "11/30/2022",
        "source": "https://en.wikipedia.org/wiki/Density"},
    "kg^1*m^-2*s^-1": {
        "id": "108",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-1*s^-2": {
        "id": "109",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^0*s^-3": {
        "id": "110",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^0": {
        "id": "111",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^-1": {
        "id": "112",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^0": {
        "id": "113",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^-1": {
        "id": "114",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^0*s^-2": {
        "id": "115",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^0": {
        "id": "116",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^-1": {
        "id": "117",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^-2": {
        "id": "118",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^0*s^-3": {
        "id": "119",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-3*s^-1": {
        "id": "120",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^-2": {
        "id": "121",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-1*s^-3": {
        "id": "122",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^3": {
        "id": "123",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^2": {
        "id": "124",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^3": {
        "id": "125",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^2": {
        "id": "126",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^1": {
        "id": "127",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^0*s^3": {
        "id": "128",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^2": {
        "id": "129",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^1": {
        "id": "130",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^0": {
        "id": "131",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^2": {
        "id": "132",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^1": {
        "id": "133",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^0": {
        "id": "134",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^3": {
        "id": "135",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^2": {
        "id": "136",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^1": {
        "id": "137",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-1*s^3": {
        "id": "138",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^-1": {
        "id": "139",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-1*s^2": {
        "id": "140",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^-1": {
        "id": "141",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^1": {
        "id": "142",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^-1": {
        "id": "143",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^0*s^3": {
        "id": "144",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^2": {
        "id": "145",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^1": {
        "id": "146",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^0": {
        "id": "147",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^3": {
        "id": "148",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^-1": {
        "id": "149",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^3": {
        "id": "150",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^-2": {
        "id": "151",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-2*s^2": {
        "id": "152",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^-2": {
        "id": "153",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-2*s^1": {
        "id": "154",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^-2": {
        "id": "155",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^0": {
        "id": "156",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^-1": {
        "id": "157",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^-2": {
        "id": "158",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^2": {
        "id": "159",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^1": {
        "id": "160",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^0": {
        "id": "161",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^2": {
        "id": "162",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^-1": {
        "id": "163",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^2": {
        "id": "164",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^-2": {
        "id": "165",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-3*s^2": {
        "id": "166",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^2*s^-3": {
        "id": "167",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-3*s^1": {
        "id": "168",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^1*s^-3": {
        "id": "169",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^0": {
        "id": "170",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-2*s^-1": {
        "id": "171",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-1*s^-2": {
        "id": "172",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^0*s^-3": {
        "id": "173",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^1": {
        "id": "174",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^-1": {
        "id": "175",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^1": {
        "id": "176",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^-2": {
        "id": "177",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^1": {
        "id": "178",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^1*s^-3": {
        "id": "179",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-3*s^-1": {
        "id": "180",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-2*s^-2": {
        "id": "181",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-1*s^-3": {
        "id": "182",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^0": {
        "id": "183",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^-1": {
        "id": "184",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^-2": {
        "id": "185",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^0": {
        "id": "186",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^-1": {
        "id": "187",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^-2": {
        "id": "188",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^0*s^-3": {
        "id": "189",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^-1": {
        "id": "190",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^-2": {
        "id": "191",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-1*s^-3": {
        "id": "192",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-3*s^-2": {
        "id": "193",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-2*s^-3": {
        "id": "194",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^3": {
        "id": "195",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^3": {
        "id": "196",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^2": {
        "id": "197",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^3": {
        "id": "198",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^2": {
        "id": "199",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^1": {
        "id": "200",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^3": {
        "id": "201",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^2": {
        "id": "202",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^1": {
        "id": "203",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^0": {
        "id": "204",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^3": {
        "id": "205",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^2": {
        "id": "206",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-1*s^3": {
        "id": "207",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^-1": {
        "id": "208",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^2": {
        "id": "209",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^-1": {
        "id": "210",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^3": {
        "id": "211",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^2": {
        "id": "212",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^1": {
        "id": "213",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-2*s^3": {
        "id": "214",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^-2": {
        "id": "215",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-2*s^2": {
        "id": "216",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^-2": {
        "id": "217",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^1": {
        "id": "218",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^-2": {
        "id": "219",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^3": {
        "id": "220",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^2": {
        "id": "221",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^1": {
        "id": "222",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^0": {
        "id": "223",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^3": {
        "id": "224",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^-1": {
        "id": "225",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^3": {
        "id": "226",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^-2": {
        "id": "227",
        "title": "Gravitational constant",
        "description": "The gravitational constant (also known as the universal gravitational constant, the Newtonian constant of gravitation, or the Cavendish gravitational constant), denoted by the capital letter G, is an empirical physical constant involved in the calculation of gravitational effects in Sir Isaac Newton's law of universal gravitation and in Albert Einstein's theory of general relativity.",
        "author": "Scott Laughlin",
        "created": "11/6/2022",
        "source": "https://en.wikipedia.org/wiki/Gravitational_constant"},
    "kg^0*m^-3*s^3": {
        "id": "228",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^3*s^-3": {
        "id": "229",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-3*s^2": {
        "id": "230",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^2*s^-3": {
        "id": "231",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^1": {
        "id": "232",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^1*s^-3": {
        "id": "233",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^0": {
        "id": "234",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^-1": {
        "id": "235",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^-2": {
        "id": "236",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^0*s^-3": {
        "id": "237",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^2": {
        "id": "238",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^-1": {
        "id": "239",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^2": {
        "id": "240",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^-2": {
        "id": "241",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^2": {
        "id": "242",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^2*s^-3": {
        "id": "243",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^-1": {
        "id": "244",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-2*s^-2": {
        "id": "245",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-1*s^-3": {
        "id": "246",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^1": {
        "id": "247",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^-2": {
        "id": "248",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^1": {
        "id": "249",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^1*s^-3": {
        "id": "250",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-3*s^-2": {
        "id": "251",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-2*s^-3": {
        "id": "252",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^0": {
        "id": "253",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^-1": {
        "id": "254",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^-2": {
        "id": "255",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^0*s^-3": {
        "id": "256",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^-1": {
        "id": "257",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^-2": {
        "id": "258",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-1*s^-3": {
        "id": "259",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^-2": {
        "id": "260",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-2*s^-3": {
        "id": "261",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^0*m^-3*s^-3": {
        "id": "262",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^3": {
        "id": "263",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^3": {
        "id": "264",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^2": {
        "id": "265",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^3": {
        "id": "266",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^2": {
        "id": "267",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^1": {
        "id": "268",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^3": {
        "id": "269",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^3": {
        "id": "270",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^-1": {
        "id": "271",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^3": {
        "id": "272",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^2": {
        "id": "273",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-2*s^3": {
        "id": "274",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^-2": {
        "id": "275",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^2": {
        "id": "276",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^-2": {
        "id": "277",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^3": {
        "id": "278",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^2": {
        "id": "279",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^1": {
        "id": "280",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-3*s^3": {
        "id": "281",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^3*s^-3": {
        "id": "282",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^2": {
        "id": "283",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^2*s^-3": {
        "id": "284",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^1": {
        "id": "285",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^1*s^-3": {
        "id": "286",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^3": {
        "id": "287",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^-1": {
        "id": "288",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^3": {
        "id": "289",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^-2": {
        "id": "290",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^3": {
        "id": "291",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^3*s^-3": {
        "id": "292",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^-1": {
        "id": "293",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^-2": {
        "id": "294",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-1*s^-3": {
        "id": "295",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^2": {
        "id": "296",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^-2": {
        "id": "297",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^2": {
        "id": "298",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^2*s^-3": {
        "id": "299",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^-2": {
        "id": "300",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-2*s^-3": {
        "id": "301",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^1": {
        "id": "302",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^1*s^-3": {
        "id": "303",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^1*m^-3*s^-3": {
        "id": "304",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^-1": {
        "id": "305",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^-2": {
        "id": "306",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-1*s^-3": {
        "id": "307",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^-2": {
        "id": "308",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-2*s^-3": {
        "id": "309",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-1*m^-3*s^-3": {
        "id": "310",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^3": {
        "id": "311",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^3": {
        "id": "312",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^2": {
        "id": "313",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^3": {
        "id": "314",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^3": {
        "id": "315",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^-2": {
        "id": "316",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^3": {
        "id": "317",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^2": {
        "id": "318",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^3": {
        "id": "319",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^3*s^-3": {
        "id": "320",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^2": {
        "id": "321",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^2*s^-3": {
        "id": "322",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^3": {
        "id": "323",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^-2": {
        "id": "324",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^3": {
        "id": "325",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^3*s^-3": {
        "id": "326",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^-2": {
        "id": "327",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-2*s^-3": {
        "id": "328",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^2": {
        "id": "329",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^2*s^-3": {
        "id": "330",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^2*m^-3*s^-3": {
        "id": "331",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^-2": {
        "id": "332",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-2*s^-3": {
        "id": "333",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-2*m^-3*s^-3": {
        "id": "334",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^3": {
        "id": "335",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^3": {
        "id": "336",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^3": {
        "id": "337",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^3*s^-3": {
        "id": "338",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^3": {
        "id": "339",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^3*s^-3": {
        "id": "340",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^3*m^-3*s^-3": {
        "id": "341",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""},
    "kg^-3*m^-3*s^-3": {
        "id": "342",
        "title": "",
        "description": "",
        "author": "",
        "created": "",
        "source": ""}
    }
